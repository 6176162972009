import(/* webpackMode: "eager" */ "/app/app/[lang]/cz/restaurantweek/restaurantweek-cz-global.css");
import(/* webpackMode: "eager" */ "/app/components/AdultModal/AdultModal.tsx");
import(/* webpackMode: "eager" */ "/app/components/Button/Button.module.css");
import(/* webpackMode: "eager" */ "/app/components/CollectionSection/CollectionSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterPL.module.css");
import(/* webpackMode: "eager" */ "/app/components/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/app/components/LogosSection/LogosSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/ReservationOfferCard/ReservationOfferCardCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/Header/HeaderCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterCHM.module.css");
import(/* webpackMode: "eager" */ "/app/components/Slider/Slider.tsx");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/ReservationOfferCard/ReservationOfferCard.module.css");
import(/* webpackMode: "eager" */ "/app/components/CollectionSection/CollectionSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/Header/Header.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/Footer.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/StatisticsSection/StatisticsSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/StatisticsSection/StatisticsSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/StatisticsSection/StatisticsSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/HeroMartiniSection/HeroSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/HeroMartiniSection/HeroSectionCHM.module.css");
import(/* webpackMode: "eager" */ "/app/components/HeroMartiniSection/HeroSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSectionCHM.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/ImagesSection/ImagesSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/ImagesSection/ImagesSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/QuoteSection/QuoteSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/QuoteSection/QuoteSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/QuoteSection/QuoteSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/ReviewsSection/ReviewsSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/ReviewsSection/ReviewsSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/TakePartSection/TakePartSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/TakePartSection/TakePartSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/cz/restaurantweek/page.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"display\":\"swap\",\"variable\":\"--font-poppins\",\"subsets\":[\"latin-ext\"]}],\"variableName\":\"poppins\"}")